<template>
<div class="infos custom-width">
    <h3>您的信息 </h3>
    <el-row>
        <el-col :span="12" :offset="0">
            <el-row :gutter="20" class="count-info">
                <el-col :span="24" :offset="0">
                    <label for="">用户名：</label>{{user}}
                </el-col>
                <el-col :span="24" :offset="0">
                    <label for="">ID：</label>{{userid}}
                </el-col>
                <el-col :span="24" :offset="0">
                    <label for="">手机号：</label>{{phone}}
                </el-col>
                <el-col :span="24" :offset="0">
                    <label for="">密码：</label>{{psword}}
                </el-col>
            </el-row>
        </el-col>
        <el-col :span="12" :offset="0">
            <el-row :gutter="20" class="count-info">
                <el-col :span="24" :offset="0">
                    <label for="">收件姓名：</label>{{user_name}}
                </el-col>
                <el-col :span="24" :offset="0">
                    <label for="">收件联系号码：</label>{{phone}}
                </el-col>
                <el-col :span="24" :offset="0">
                    <label for="">收件地址：</label>{{user_address}}
                </el-col>
            </el-row>
        </el-col>

    </el-row>

</div>
</template>

<script>
export default {
    data() {
        return {
            userid:"",
            user: "匿名",
            phone: "1588888888",
            psword: "******",
            user_address:"",
            user_name:"",
            member:""
        }
    },
    created(){
        if (sessionStorage.getItem("loginState") != 1) {
                this.$message("请先登录")
                this.$router.push({path:"/login"})
            }
        this.member = JSON.parse(sessionStorage.getItem("member"))
        this.user = this.member.Member_Name
        this.phone = this.member.Member_Phone
        this.userid = this.member.Member_ID
        
    }
}
</script>

<style scoped>
.infos {
    margin: 10px auto;
    line-height: 40px;
    padding: 20px;
}

.count-info label {
    display: inline-block;
    width: 120px;
}

.count-info {
    width: calc(100% - 20px);
    text-align: left;
    padding:10px;
    margin: 0 auto!important;
    box-sizing: border-box;
    border: 1px solid #dedede;
    border-radius: 6px;
}
</style>
